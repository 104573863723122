@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@font-face {
  font-family: AntennaExtraCondBold;
  src: url(styles/fonts/AntennaExtraCond-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: BrushScript;
  src: url(styles/fonts/BrushScript.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: CenturyGothic;
  src: url(styles/fonts/CenturyGothic.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: NikeFieldTypeFootball;
  src: url(styles/fonts/NikeFieldTypeFootball.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Swiss;
  src: url(styles/fonts/Swiss-721-BT.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --black-color: hsla(0, 0%, 0%, 1);
  --black3-color: hsla(0, 0%, 3%, 1);
  --black6-color: hsla(0, 0%, 6%, 1);
  --black9-color: hsla(0, 0%, 9%, 1);
  --black10-color: hsla(0, 0%, 10%, 1);
  --black12-color: hsla(0, 0%, 12%, 1);
  --black27-color: hsla(0, 0%, 27%, 1);
  --black-transparent10-color: hsla(0, 0%, 0%, 0.1);
  --black-transparent15-color: hsla(0, 0%, 0%, 0.15);
  --black-transparent25-color: hsla(0, 0%, 0%, 0.25);
  --black-transparent50-color: hsla(0, 0%, 0%, 0.5);

  --white-color: hsla(0, 0%, 100%, 1);
  --white90-color: hsla(0, 0%, 90%, 1);
  --white95-color: hsla(0, 0%, 95%, 1);
  --white-transparent075-color: hsla(0, 0%, 100%, 0.075);
  --white-transparent15-color: hsla(0, 0%, 100%, 0.15);
  --white-transparent25-color: hsla(0, 0%, 100%, 0.25);
  --white-transparent50-color: hsla(0, 0%, 100%, 0.5);
  --white-transparent80-color: hsla(0, 0%, 100%, 0.8);

  --primary-color: hsla(72, 100%, 50%, 1);
  --primary-hover-color: hsla(72, 100%, 40%, 1);
  --primary-active-color: hsla(72, 100%, 30%, 1);

  --warning-color: hsla(40, 85%, 50%, 1);

  --error-color: hsla(10, 100%, 50%, 1);
  --error-background-color: hsla(10, 100%, 50%, 0.2);

  --status-new-color: hsla(352, 84%, 62%, 0.8);
  --status-progress-color: hsla(216, 72%, 48%, 0.8);
  --status-submit-color: hsla(148, 38%, 50%, 0.8);

  --heading-font-family: AntennaExtraCondBold;

  --header-height: 4.125rem;
  --header-background-color: var(--black6-color);
  --header-border-color: var(--white-transparent15-color);

  --list-header-border-color: var(--white-transparent15-color);
  --list-background-color: var(--black-color);
  --list-color: var(--white-color);

  --product-card-background-color1: var(--black12-color);
  --product-card-background-color2: var(--black16-color);
  --product-card-loading-color: radial-gradient(
    hsla(0, 0%, 6%, 0.9) 10%,
    hsla(0, 0%, 8%, 0.9),
    hsla(0, 0%, 10%, 0.9),
    hsla(0, 0%, 12%, 0.9),
    hsla(0, 0%, 12%, 0.8)
  );
  --product-card-title-color: var(--primary-color);
  --product-card-subtitle-color: hsla(206, 19%, 93%, 1);

  --designer-loading-color: var(--white-color);
  --designer-background-color: var(--black-color);
  --designer-color: var(--white-color);
  --designer-header-height: 3.5rem;
  --designer-header-background-color: var(--black6-color);
  --designer-header-border-color: var(--white-transparent15-color);
  --designer-slide-background-color: var(--black-color);

  --designer-footer-height: 5.25rem;
  --designer-footer-background-color: var(--black10-color);
  --designer-footer-border-color: var(--white-transparent15-color);
  --designer-footer-seperator-color: var(--white-transparent075-color);
  --footer-navitem-active-background-color: none;
  --footer-navitem-color: hsla(206, 19%, 93%, 1);
  --footer-navitem-active-color: var(--primary-color);

  --thumb-navitem-background-color: var(--black12-color);
  --thumb-navitem-active-background-color: var(--black27-color);
  --thumb-navitem-active-border-color: var(--black27-color);
  --thumb-navitem-color: hsla(0, 0%, 85%, 1);

  --index-slider-background-color: var(--black12-color);
  --index-slider-active-background-color: hsla(206, 4%, 90%, 1);

  --designer-toolbar-width: 27.5rem;
  --designer-toolbar-height: 40dvh;
  --designer-toolbar-background-color: var(--white-color);
  --designer-toolbar-border-color: var(--black-transparent15-color);
  --designer-toolbar-close-color: inherit;
  --designer-toolbar-header-height: 4rem;
  --designer-toolbar-header-color: var(--black12-color);

  --config-item-color: var(--black12-color);
  --config-item-border-color: var(--black-transparent10-color);

  --layout-padding: 1.25rem;
  @media screen and (min-width: 1024px) {
    --header-height: 4.5rem;
    --layout-padding: 1.5rem;
    --designer-header-height: 4.125rem;
    --designer-footer-height: 6.5rem;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit;
}

html,
body,
#root {
  min-height: 100dvh;
}

html {
  color-scheme: dark light;
  accent-color: var(--primary-color);
  overflow: hidden;
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  line-height: 1.2;
  background-color: var(--black-color);
  color: var(--white-color);
  color-scheme: dark;
  @media screen and (min-width: 1024px) {
    --designer-footer-background-color: none;
    --designer-toolbar-background-color: var(--black6-color);
    --designer-toolbar-border-color: var(--white-transparent15-color);
    --designer-toolbar-close-color: var(--white-transparent25-color);
    --designer-toolbar-header-color: var(--white-color);

    --config-item-color: var(--white-transparent50-color);
    --config-item-border-color: var(--white-transparent075-color);
  }
  &.light {
    --header-border-color: var(--header-background-color);

    --list-header-border-color: var(--black-transparent15-color);
    --list-background-color: var(--white-color);
    --list-color: var(--black-color);

    --product-card-background-color1: var(--white95-color);
    --product-card-background-color2: var(--white95-color);
    --product-card-loading-color: radial-gradient(
      hsla(0, 0%, 82%, 0.9) 10%,
      hsla(0, 0%, 86%, 0.9),
      hsla(0, 0%, 92%, 0.9),
      hsla(0, 0%, 95%, 0.9),
      hsla(0, 0%, 95%, 1)
    );
    --product-card-title-color: var(--black-color);
    --product-card-subtitle-color: var(--black27-color);

    --designer-loading-color: var(--black12-color);
    --designer-background-color: hsla(210, 25%, 98%, 1);
    --designer-color: var(--black-color);
    --designer-header-background-color: var(--white-color);
    --designer-header-border-color: var(--black-transparent15-color);
    --designer-slide-background-color: hsla(210, 25%, 98%, 1);

    --designer-footer-background-color: hsla(210, 25%, 98%, 1);
    --designer-footer-border-color: var(--black-transparent15-color);
    --designer-footer-seperator-color: var(--black-transparent10-color);
    --footer-navitem-active-background-color: var(--black-transparent10-color);
    --footer-navitem-color: var(--black-color);
    --footer-navitem-active-color: var(--black-color);

    --thumb-navitem-background-color: var(--white90-color);
    --thumb-navitem-active-background-color: var(--white90-color);
    --thumb-navitem-active-border-color: var(--black-color);
    --thumb-navitem-color: var(--black-color);

    --index-slider-background-color: hsla(206, 4%, 90%, 1);
    --index-slider-active-background-color: var(--black12-color);

    --designer-toolbar-background-color: var(--white-color);
    --designer-toolbar-border-color: var(--black-transparent15-color);
    --designer-toolbar-close-color: inherit;
    --designer-toolbar-header-color: var(--black12-color);

    --config-item-color: var(--black12-color);
    --config-item-border-color: var(--black-transparent10-color);

    color-scheme: light;
  }
}

a {
  color: inherit;
  text-decoration: none;
}
