.container {
  > input {
    width: 100%;
    height: 3.5rem;
    padding: 0.5rem 1rem;
    border: 2px solid var(--white-color);
    border-radius: 4px;
    background-color: var(--white-color);
    color: var(--black-color);
    font-weight: 500;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    transition: all 0.2s ease;
    &:is([disabled], [readonly]) {
      pointer-events: none;
    }
  }
  &.light {
    > input {
      color: var(--black-color);
      background-color: hsla(206, 19%, 93%, 0.3);
      border: 1px solid hsla(206, 19%, 93%, 1);
    }
  }
  &.dark {
    > input {
      color: var(--white-transparent80-color);
      background-color: hsla(206, 19%, 93%, 0.02);
      border: 1px solid hsla(0, 0%, 100%, 0.05);
    }
  }
}
