.container {
  display: flex;
  align-items: center;
  > span {
    margin-right: 8px;
    color: var(--black12-color);
    font-weight: 500;
  }
  &.dark {
    > span {
      color: var(--white-transparent50-color);
    }
  }
}
