.container {
  > textarea {
    width: 100%;
    height: 5rem;
    padding: 0.5rem;
    color: var(--black-color);
    background-color: hsla(206, 19%, 93%, 0.3);
    border: 1px solid hsla(206, 19%, 93%, 1);
    border-radius: 0.25rem;
    box-shadow: none;
    outline: none;
    resize: none;
    overflow: hidden;
    &:is([disabled], [readonly]) {
      pointer-events: none;
    }
  }
  &.dark {
    > textarea {
      color: var(--white-transparent80-color);
      background-color: hsla(206, 19%, 93%, 0.02);
      border: 1px solid hsla(0, 0%, 100%, 0.05);
    }
  }
}
