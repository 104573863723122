.container {
  position: relative;
  .valueContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--black12-color);
    cursor: pointer;
  }
  .menu {
    min-width: 12rem;
    position: absolute;
    top: 0;
    left: 0;
    color: var(--black27-color);
    background-color: var(--white-color);
    border: 1px solid var(--black-transparent15-color);
    border-radius: 0.5rem;
    font-size: 0.9rem;
    overflow: hidden;
    z-index: 1;
    .menuItem {
      padding: 0 1rem;
      cursor: pointer;
      &:hover {
        background-color: hsla(0, 0%, 98%, 1);
      }
      &:active {
        background-color: hsla(0, 0%, 96%, 1);
      }
      &:last-child {
        .menuOption {
          border-bottom: 0 none;
        }
      }
      .menuOption {
        padding: 0.75rem 0;
        border-bottom: 1px solid var(--black-transparent15-color);
      }
    }
  }
  &.medium {
    .valueContainer {
      font-weight: 500;
    }
    .menu {
      font-weight: 500;
    }
  }
  &.large {
    .valueContainer {
      .value {
        font-size: 2rem;
      }
    }
    .menu {
      font-size: 1.5rem;
    }
  }
  &.right {
    .menu {
      left: auto;
      right: 0;
      text-align: right;
    }
  }
  &.dark {
    .valueContainer {
      color: var(--primary-color);
    }
    .menu {
      color: var(--white-color);
      background-color: var(--black6-color);
      border: 1px solid var(--white-transparent15-color);
      .menuItem {
        &:hover {
          background-color: var(--black9-color);
        }
        &:active {
          background-color: var(--black3-color);
        }
      }
      .menuOption {
        border-bottom: 1px solid var(--white-transparent15-color);
      }
    }
  }
}
