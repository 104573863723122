.container {
  .tabsList {
    display: flex;
    height: 3.125rem;
    font-weight: 500;
    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      color: var(--black27-color);
      background-color: var(--white95-color);
      border-bottom: 2px solid var(--white95-color);
      cursor: pointer;
      &.active {
        color: var(--black12-color);
        background-color: var(--white-color);
        border-bottom: 2px solid var(--black-color);
      }
    }
    &.dark {
      .tab {
        color: var(--white-color);
        background-color: var(--black12-color);
        border-bottom: 2px solid var(--black12-color);
        &.active {
          color: var(--primary-color);
          background-color: inherit;
          border-bottom: 2px solid var(--primary-color);
        }
      }
    }
  }
}
