.loadingContainer {
  .welcomeMessage {
    width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    text-align: center;
    font-family: var(--heading-font-family);
    z-index: 1;
    transition: all 0.6s ease-out;
    .greet {
      font-size: 2.75rem;
    }
    .user {
      color: var(--primary-color);
      font-size: 3.5rem;
    }
    &.slideUp {
      top: 20%;
      scale: 0.78;
    }
  }
  @keyframes fadeIn {
    0%,
    10% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .logoContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    animation: fadeIn 0.3s linear;
  }
}

.container {
  position: relative;
  height: 100dvh;
  overflow: hidden;
  .headerContainer,
  .secondHeaderContainer {
    padding: 0 var(--layout-padding);
    .header {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      align-items: center;
      justify-content: space-between;
      max-width: 80rem;
      height: 100%;
      margin: 0 auto;
    }
  }

  .headerContainer {
    position: absolute;
    inset: 0 0 auto 0;
    height: var(--header-height);
    background-color: var(--header-background-color);
    border-bottom: 1px solid var(--header-border-color);
    .logoContainer {
      @media screen and (min-width: 1024px) {
        > svg {
          width: 78.4px;
          height: 28px;
        }
      }
    }
    .userThemeContainer {
      display: grid;
      grid-template-columns: 1.5rem 1px 1fr;
      align-items: center;
      gap: 1.5rem;
      .seperator {
        height: 1.5rem;
        border-left: 1px solid var(--white-transparent15-color);
      }
      .theme {
        cursor: pointer;
      }
      @keyframes rotateClock {
        0%,
        1% {
          opacity: 0;
          rotate: -120deg;
        }
        100% {
          opacity: 1;
          rotate: 0deg;
        }
      }
      @keyframes rotateAnticlock {
        0%,
        1% {
          opacity: 0;
          rotate: 120deg;
        }
        100% {
          opacity: 1;
          rotate: 0deg;
        }
      }
      .animate {
        .rotateClock {
          animation: rotateClock 0.3s ease;
        }
        .rotateAnticlock {
          animation: rotateAnticlock 0.3s ease;
        }
      }
    }
    .userContainer {
      display: grid;
      grid-template-columns: 1fr 2.375rem;
      align-items: center;
      gap: 0.75rem;
      text-align: right;
      @media screen and (min-width: 1024px) {
        grid-template-columns: 1fr 3rem;
      }
      .userInfo {
        font-family: var(--heading-font-family);
      }
      .userName {
        color: var(--white-color);
      }
      .team {
        color: hsla(0, 0%, 100%, 0.5);
        font-size: 0.8rem;
      }
      .userIcon {
        aspect-ratio: 1 / 1;
        border: 2px solid #ffffff;
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 20%;
        }
      }
    }
  }

  .secondHeader {
    padding: 1rem 0;
    border-bottom: 1px solid var(--list-header-border-color);
    .title {
      font-family: var(--heading-font-family);
      font-size: 1.75rem;
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
    .seasonContainer {
      font-family: var(--heading-font-family);
      @media screen and (min-width: 1024px) {
        display: grid;
        grid-auto-flow: column;
        gap: 0.75rem;
        align-items: center;
      }
      .seasonLabel {
        font-size: 1.2rem;
        @media screen and (min-width: 1024px) {
          font-size: 1.5rem;
        }
      }
    }
  }

  .ordersMainSection {
    position: absolute;
    inset: var(--header-height) 0 0 0;
    .ordersSection {
      height: 100%;
      background-color: var(--list-background-color);
      color: var(--list-color);
      overflow: auto;
    }
  }

  @media screen and (max-width: 1023px) {
    &.edit {
      .headerContainer {
        transition: top 0.3s ease;
        top: calc(var(--header-height) * -1);
      }
    }
    &.editClose {
      .headerContainer {
        transition: top 0.3s ease;
        top: 0;
      }
    }
  }

  .ordersContainer {
    padding: var(--layout-padding);
    color: var(--white-color);
    .ordersListContainer {
      max-width: 80rem;
      margin: 0 auto;
    }
    .ordersList {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0.75rem;
      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1rem;
      }
      @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
    .productCard {
      display: grid;
      grid-template-rows: minmax(0, 1fr) max-content;
      gap: 0.25rem;
      aspect-ratio: 3 / 4;
      padding: 0.75rem;
      background-color: var(--product-card-background-color1);
      background-image: linear-gradient(
        var(--product-card-background-color1),
        var(--product-card-background-color2)
      );
      border-radius: 0.5rem;
      cursor: pointer;
      overflow: hidden;
      @media screen and (min-width: 1024px) {
        gap: 1rem;
        padding: 1rem;
      }
      .thumbnail {
        display: flex;
        place-content: center;
        > img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          transition: opacity 0.2s ease, scale 0.6s ease;
        }
      }
      &:hover {
        img {
          scale: 1.1 1.1;
        }
      }
      .productInfo {
        display: grid;
        gap: 0.4rem;
        .status {
          display: inline-block;
          padding: 0.2rem 0.4rem;
          background-color: var(--status-new-color);
          border-radius: 0.25rem;
          font-size: 0.7rem;
          font-weight: 600;
          text-transform: uppercase;
          &.new {
            background-color: var(--status-new-color);
          }
          &.inProgress {
            background-color: var(--status-progress-color);
          }
          &.submitted {
            background-color: var(--status-submit-color);
          }
        }
        .nameNfamily {
          display: grid;
          grid-auto-flow: column;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;
        }
        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: var(--product-card-title-color);
        }
        .family {
          display: list-item;
          font-size: 0.8rem;
          &:global(.home) {
            color: #4ac783;
          }
          &:global(.away) {
            color: #e99b27;
          }
          &:global(.alt) {
            color: #c74a4a;
          }
        }
        .sizeNdate {
          display: grid;
          grid-auto-flow: column;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
        }
        .size {
          color: var(--product-card-title-color);
        }
        .date {
          color: var(--product-card-subtitle-color);
          font-size: 0.75rem;
        }
      }
    }
    .productLoadingCard {
      position: relative;
      aspect-ratio: 3 / 4;
      background-color: var(--product-card-background-color1);
      border-radius: 0.5rem;
      overflow: hidden;
      @keyframes slideToRight {
        0% {
          translate: -100% 0;
        }
        100% {
          translate: 100% 0;
        }
      }
      &::after {
        content: '';
        display: block;
        height: 100%;
        aspect-ratio: 1 / 1;
        background-image: var(--product-card-loading-color);
        animation: slideToRight 1s linear infinite;
      }
    }
  }

  .errorMsg {
    display: grid;
    text-align: center;
    font-weight: 500;
    margin-top: 20dvh;
    .header {
      font-size: 1.5rem;
    }
  }
}
