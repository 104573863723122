.button {
  height: 2.75rem;
  position: relative;
  overflow: hidden;
  padding: 0.5rem 1.5rem;
  background-color: var(--white-transparent80-color);
  border: 0 none;
  border-radius: 0.25rem;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  @media screen and (min-width: 1024px) {
    height: 3rem;
  }
  &.large {
    height: 3.625rem;
  }
  &.default {
    color: var(--white-color);
    background-color: var(--white-transparent15-color);
  }
  &.primary {
    color: var(--black-color);
    background-color: var(--primary-color);
    &:hover {
      background-color: var(--primary-hover-color);
    }
    &:active {
      background-color: var(--primary-active-color);
    }
  }
  &.outline {
    color: var(--black-color);
    background-color: inherit;
    border: 2px solid var(--black-color);
  }
  &.primaryOutline {
    color: var(--black-color);
    background-color: inherit;
    border: 2px solid var(--black-color);
  }
  &.dark {
    &.default {
      color: var(--white-color);
      background-color: var(--white-transparent15-color);
    }
    &.primary {
      color: var(--black-color);
      background-color: var(--primary-color);
      &:hover {
        background-color: var(--primary-hover-color);
      }
      &:active {
        background-color: var(--primary-active-color);
      }
    }
    &.outline {
      color: var(--white-color);
      background-color: inherit;
      border: 2px solid var(--white-color);
    }
    &.primaryOutline {
      color: var(--primary-color);
      background-color: inherit;
      border: 2px solid var(--primary-color);
    }
  }
  @keyframes progress {
    0% {
      translate: -100% 0;
      opacity: 1;
    }
    100% {
      translate: 100% 0;
      opacity: 1;
    }
  }
  .progress {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.3rem;
    border-radius: 0.15rem;
    background-image: linear-gradient(
      to right,
      hsla(0, 0%, 0%, 0),
      hsla(0, 0%, 0%, 0.25)
    );

    animation: progress 1.5s ease-in infinite;
  }
}
