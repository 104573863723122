.container {
  display: grid;
  place-items: center;
  position: absolute;
  inset: 0;
  background-color: var(--black-color);
  .messageContainer {
    display: grid;
    gap: 1rem;
    place-content: center;
    text-align: center;
    width: calc(100dvw - var(--layout-padding) * 2);
    max-width: 25rem;
    aspect-ratio: 1 / 1;
    padding: var(--layout-padding);
    background-color: hsla(36, 4%, 12%, 1);
    border-radius: 1rem;
    @media screen and (min-width: 768px) {
      max-width: 40rem;
      aspect-ratio: 2 / 1;
      background-color: hsla(0, 0%, 8%, 1);
    }
    .icon {
      @media screen and (max-width: 1023px) {
        > svg {
          width: 64px;
          height: 64px;
        }
      }
    }
    .header {
      color: var(--warning-color);
      font-family: var(--heading-font-family);
      font-size: 1.75rem;
      font-weight: 700;
    }
    .message {
      font-size: 1.25rem;
    }
  }
}
