.container {
  .label {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    position: relative;
    color: var(--black27-color);
    cursor: pointer;
    .checkContainer {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      border-width: 2px;
      border-style: solid;
      border-color: var(--white90-color);
      border-radius: 50%;
      transition: border-color 0.2s ease;
      .check {
        width: 10px;
        height: 10px;
        scale: 0 0;
        border-radius: 50%;
        background-color: var(--black-color);
        transition: scale 0.2s ease;
      }
    }
    .radio {
      position: absolute;
      visibility: hidden;
      display: none;
      &:checked + .checkContainer {
        border-color: var(--black-color);
        .check {
          scale: 1 1;
        }
      }
    }
  }
  &.dark {
    .label {
      color: var(--white-transparent80-color);
    }
    .checkContainer {
      .check {
        background-color: var(--primary-color);
      }
    }
    .radio:checked + .checkContainer {
      border-color: var(--primary-color);
    }
    &:has(:checked) {
      .label {
        color: var(--primary-color);
      }
    }
  }
}
