.container {
  width: 100%;
  color: var(--black-color);
  .listContainer {
    width: 100%;
    padding: calc(var(--layout-padding) * 0.5) var(--layout-padding);
    &.alignCenter {
      text-align: center;
    }
    &.scroll {
      overflow-x: auto;
      scroll-behavior: smooth;
      overscroll-behavior-inline: contain;
      &::-webkit-scrollbar {
        display: none;
      }
      .list {
        flex-wrap: nowrap;
      }
    }
  }
  .list {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    .listItem {
      cursor: pointer;
    }
    &.noScroll {
      flex-wrap: wrap;
    }
    &.color {
      .listItem {
        position: relative;
        width: 3.375rem;
        height: 3.375rem;
        border-radius: 50%;
        overflow: hidden;
        .colorIcon {
          width: 50%;
          height: 100%;
          background-size: contain;
        }
        &.active {
          box-shadow: 0 0 0 3px var(--white-color),
            0 0 0 6px rgba(20, 20, 20, 0.5);
        }
      }
      &.dark {
        .listItem {
          &.active {
            box-shadow: 0 0 0 3px var(--black-color),
              0 0 0 6px var(--primary-color);
          }
        }
      }
    }
    &.pill {
      font-size: 0.9rem;
      font-weight: 500;
      .listItem {
        display: flex;
        align-items: center;
        height: 3rem;
        padding: 0.75rem;
        color: var(--black27-color);
        background-color: var(--white95-color);
        border-radius: 1.5rem;
        white-space: nowrap;
        &.active {
          color: var(--white-color);
          background-color: var(--black-color);
        }
      }
      &.dark {
        .listItem {
          color: var(--white-color);
          background-color: var(--black12-color);
          border: 1px solid var(--black12-color);
          &.active {
            color: var(--primary-color);
            background-color: inherit;
            border: 1px solid var(--primary-color);
          }
        }
      }
    }
    &.circularPill {
      font-size: 0.9rem;
      font-weight: 500;
      .listItem {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        padding: 0.75rem;
        color: var(--black27-color);
        background-color: var(--white95-color);
        border-radius: 50%;
        white-space: nowrap;
        &.active {
          color: var(--white-color);
          background-color: var(--black-color);
        }
      }
      &.dark {
        .listItem {
          color: var(--white-color);
          background-color: var(--black12-color);
          border: 1px solid var(--black12-color);
          &.active {
            color: var(--primary-color);
            background-color: inherit;
            border: 1px solid var(--primary-color);
          }
        }
      }
    }
    &.logo {
      .listItem {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7.6rem;
        height: 7.6rem;
        background-color: hsla(206, 19%, 93%, 0.3);
        border: 1px solid hsl(206, 19%, 93%);
        border-radius: 0.5rem;
        > img {
          max-width: 80%;
        }
        &.active {
          border: 2px solid var(--black-color);
        }
      }
      &.dark {
        .listItem {
          background-color: var(--black12-color);
          border: 1px solid var(--black12-color);
          &.active {
            border: 1px solid var(--primary-color);
          }
        }
      }
    }
  }

  .listCaption {
    padding: 0 var(--layout-padding);
    font-size: 0.9rem;
    .caption {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: calc(var(--layout-padding) * 0.75) 0;
      border-bottom: 1px solid var(--black-transparent10-color);
      .label {
        color: var(--black12-color);
        font-weight: 500;
      }
      .value {
        font-weight: 600;
      }
    }
    &.dark {
      .caption {
        border-bottom: 1px solid var(--white-transparent075-color);
      }
      .label {
        color: var(--white-transparent50-color);
      }
      .value {
        color: var(--white-color);
      }
    }
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.colorOptionsContainer {
  width: 230px;
  height: 68px;
  position: absolute;
  left: -1000px;
  top: -100px;
  translate: -90px -80px;
  transform-origin: 50% 100%;
  animation: 0.3s zoomIn ease;
  background: #fff;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  &::before,
  &::after {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-12px);
    width: 0;
    height: 0;
    content: '';
    margin: auto;
    border-style: solid;
    border-width: 10px 12px 0 12px;
  }
  &::before {
    border-color: rgba(0, 0, 0, 0.15) transparent transparent transparent;
    bottom: -11px;
  }
  &::after {
    border-color: white transparent transparent transparent;
    bottom: -10px;
  }
  &.right {
    left: auto;
    right: -20px;
    margin-left: 0;
    transform: translateX(0);
    transform-origin: 80% 100%;
    &::before,
    &::after {
      left: 80%;
    }
  }
  &.left {
    left: -20px;
    margin-left: 0;
    transform: translateX(0);
    transform-origin: 20% 100%;
    &::before,
    &::after {
      left: 20%;
    }
  }
}
