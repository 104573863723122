.container {
  position: relative;
  height: 100%;
  .slider {
    max-width: 80rem;
    height: 100%;
    margin: 0 auto;
    position: relative;
    isolation: isolate;
    @media screen and (max-width: 1023px) {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 100%;
      grid-template-rows: calc(100% - 24px);
      gap: 1rem;
      overflow-x: auto;
      scroll-behavior: smooth;
      overscroll-behavior-inline: contain;
      scroll-snap-type: inline mandatory;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    @media screen and (min-width: 1024px) {
      overflow: hidden;
    }
    .slide {
      background-color: var(--designer-slide-background-color);
      @media screen and (max-width: 1023px) {
        scroll-snap-align: start;
        scroll-snap-stop: always;
      }
      @media screen and (min-width: 1024px) {
        position: absolute;
        inset: 0;
        z-index: 0;
        @keyframes slideInLeft {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(0%);
          }
        }
        @keyframes slideInRight {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(0%);
          }
        }
        @keyframes slideOutLeft {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
        @keyframes slideOutRight {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(100%);
          }
        }
      }
      .innerSlide {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        container-type: size;
      }
      .aspectRatioContainer {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        @container (orientation: landscape) {
          height: 100%;
          width: auto;
        }
        > * {
          padding: 1rem;
        }
      }
      &.active {
        z-index: 2;
        &.left {
          animation: slideInRight 0.3s ease-in-out;
        }
        &.right {
          animation: slideInLeft 0.3s ease-in-out;
        }
      }
      &.inactive {
        z-index: 1;
        &.left {
          animation: slideOutLeft 0.35s ease-in-out;
        }
        &.right {
          animation: slideOutRight 0.35s ease-in-out;
        }
      }
    }
  }

  .navContainer {
    padding: 0 var(--layout-padding);
    .navListContainer {
      position: relative;
      max-width: 80rem;
      margin: 0 auto;
    }
    .navList {
      display: grid;
      gap: 1rem;
      position: absolute;
      left: 0;
      top: var(--layout-padding);
      z-index: 1;
      .navItem {
        display: grid;
        place-items: center;
        position: relative;
        width: 3.5rem;
        height: 3.5rem;
        background-color: var(--thumb-navitem-background-color);
        border: 2px solid transparent;
        border-radius: 0.25rem;
        cursor: pointer;
        > img {
          max-width: 3rem;
          max-height: 3rem;
        }
        > svg > path {
          fill: var(--thumb-navitem-color);
        }
        .navTitle {
          display: none;
          position: absolute;
          left: 100%;
          top: 50%;
          translate: 0.5rem -50%;
          padding: 0.5rem 1rem;
          white-space: nowrap;
          background-color: var(--thumb-navitem-active-background-color);
          border-radius: 0.25rem;
          font-size: 0.9rem;
          font-weight: 500;
        }
        &.active {
          background-color: var(--thumb-navitem-active-background-color);
          border: 2px solid var(--thumb-navitem-active-border-color);
        }
        &:hover {
          background-color: var(--thumb-navitem-active-background-color);
          .navTitle {
            display: block;
          }
        }
      }
    }
  }

  .indexSlider {
    display: flex;
    place-content: center;
    gap: 0.25rem;
    position: absolute;
    inset: auto 0 0 0;
    height: 24px;
    .slide {
      width: 1.25rem;
      height: 0.25rem;
      background-color: var(--index-slider-background-color);
      border-radius: 0.125rem;
      transition: background-color 0.25s ease;
      &.active {
        background-color: var(--index-slider-active-background-color);
      }
    }
  }
}

.canvasContainer {
  width: 100%;
  height: 100%;
  padding: var(--layout-padding);
  position: relative;

  .loadingContainer {
    display: grid;
    place-items: center;
    position: absolute;
    inset: 0;
    padding: 1rem;
    background-color: var(--designer-background-color);
    z-index: 1;
    > svg {
      max-width: 100%;
      max-height: 100%;
      path {
        fill: var(--designer-loading-color);
      }
    }
  }

  .innerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100% !important;
      height: 100% !important;
    }
    canvas {
      &:not(.helperCanvas) {
        left: auto !important;
        top: auto !important;
        width: auto !important;
        height: auto !important;
      }
    }
    .helperCanvas {
      width: 100%;
      height: 100%;
      position: absolute;
      left: -10000px;
      top: -10000px;
      z-index: -1;
    }
  }
}
