.container {
  display: grid;
  place-items: center;
  min-height: 100dvh;
  > * {
    grid-area: 1 / 1;
  }
  @keyframes fadeIn {
    0%,
    10% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .gradientContainer {
    width: min(100dvw, 100dvh);
    aspect-ratio: 1 / 1;
    background-image: radial-gradient(
      hsla(0, 0%, 100%, 0.12),
      hsla(0, 0%, 100%, 0.08),
      hsla(0, 0%, 100%, 0.01),
      var(--black-color)
    );
    animation: fadeIn 0.5s ease-out;
  }
  .logoContainer {
    display: flex;
    place-content: center;
    animation: fadeIn 0.3s linear;
  }
}
