.container {
  height: 100dvh;
  @keyframes slideUp {
    0%,
    10% {
      translate: -50% -50%;
    }
    100% {
      translate: -50% calc(-50% - 180px);
    }
  }
  @keyframes scaleDown {
    0%,
    10% {
      scale: 1;
    }
    100% {
      scale: 0.78;
    }
  }
  @keyframes fadeIn {
    0%,
    10% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% calc(-50% - 180px);
    z-index: 1;
    animation: slideUp 0.5s linear;
    @media screen and (max-width: 1023px) and (orientation: landscape) {
      display: none;
    }
    > svg {
      scale: 0.78;
      animation: scaleDown 0.5s linear;
    }
  }
  .loginContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    width: 100%;
    max-width: 30rem;
    padding: var(--layout-padding);
    animation: fadeIn 0.3s linear;
    .title {
      margin-bottom: var(--layout-padding);
      font-family: var(--heading-font-family);
      font-size: 1.75rem;
      text-align: center;
    }
    .loginForm {
      display: grid;
      gap: 1rem;

      .accessCode {
        > input {
          height: 3.625rem;
          font-weight: 600;
        }
        &.error > input {
          color: var(--white-color);
          background-color: var(--error-background-color);
          border: 2px solid var(--error-color);
          caret-color: var(--white-color);
        }
      }
    }
  }
  .message {
    position: absolute;
    left: 50%;
    bottom: 0;
    translate: -50% 0;
    width: 100%;
    max-width: 50rem;
    padding: var(--layout-padding);
    font-size: 0.75rem;
    text-align: center;
  }
  .errorMsg {
    color: var(--error-color);
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: -0.75rem;
  }
}
